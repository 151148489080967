/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import favicon from "../../favicon.png"

function SEO({ description, lang, meta, title, conversion }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )

  const schemaMarkUp = {
    "@context": "http://schema.org",
    "@type": "Organization",
    "name": "Archerhub",
    "description": "Archerhub is the premier digital marketplace for truckload freight where shippers and drivers are connected through AI-enabled technology.",
    "telephone": "+1 303-542-7110",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "1860 Blake Street Suite 620",
      "addressLocality": " Denver",
      "addressRegion": "CO",
      "postalCode": "80202",
      "addressCountry": "US"
    },
    "sameAs": ["https://www.facebook.com/archerhub/", 
    "https://www.linkedin.com/company/archerhub/", 
    "https://twitter.com/ArcherHub"],
    "department":
    [{
      "@type": "Organization",
      "name": "Archerhub Denver",
      "address": {
      "@type": "PostalAddress",
      "streetAddress": "1860 Blake Street Suite 620",
      "addressLocality": " Denver",
      "addressRegion": "CO",
      "postalCode": "80202",
      "addressCountry": "US"
    }},
    {
      "@type": "Organization",
      "name": "Archerhub Sacramento",
      "address":
      {
        "@type": "PostalAddress",
        "streetAddress": "3009 Douglas Blvd. Suite 110",
        "addressLocality": "Sacramento",
        "addressRegion": "CA",
        "addressCountry": "US",
        "postalCode": "95661"
      }
    }]
  };
  

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: `freight, logistics, shipping, truck, truckload, carrier, broker, brokerage, archer, hub, archerhub, global, driver, loads, ship, shipper, track, tracking, industry, mobile, technology, artificial, intelligence, automate`
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <link rel="shortcut icon" type="image/png" href={favicon}/>

      {/* <!-- Start of HubSpot Embed Code --> */}
      {/* <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/6337735.js"></script> */}
      {/* <!-- End of HubSpot Embed Code --> */}

      <link href="https://unpkg.com/ionicons@4.5.10-0/dist/css/ionicons.min.css" rel="stylesheet"></link>
      <script type="text/javascript">
       { `(function (d, t) {
          var pp = d.createElement(t), s = d.getElementsByTagName(t)[0];
          pp.src = '//app.pageproofer.com/overlay/js/4672/2420';
          pp.type = 'text/javascript';
          pp.async = true;
          s.parentNode.insertBefore(pp, s);
        })(document, 'script');`}
    </script>
    <script type="application/ld+json">{JSON.stringify(schemaMarkUp)}</script>
    <script>
      {conversion?`gtag('event', 'conversion', {'send_to': 'AW-693057883/FfNoCIrI5rsBENvyvMoC'}); `:''}
    </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
